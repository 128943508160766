// This is the scss entry file
import "../styles/index.scss";
import "../img/scratch-cover.png";
import "../img/scratch-logo.png";

window.document.addEventListener("DOMContentLoaded", function () {
  window.console.log("dom ready 1");
});

const hamburger = document.querySelector('#hamburger');
const menu = document.querySelector('#menu');

hamburger.addEventListener('click', () => {
  if (menu.classList.contains('hidden')) {
    menu.classList.remove('hidden');
  } else {
    menu.classList.add('hidden');
  }
});


const loginButton = document.querySelector('#login-button');
const registraionButton = document.querySelector('#registration-button');
const loginForm = document.querySelector('#login-form');
const registrationForm = document.querySelector('#registration-form');
const loginText = document.querySelector('#login-text');
const registerText = document.querySelector('#register-text');
const loginMessage = document.querySelector('#login-message');
const registerMessage = document.querySelector('#register-message');

loginButton.addEventListener('click', () => {
  toggleLogin();
});

registraionButton.addEventListener('click', () => {
  toggleRegistration();
});

function toggleRegistration() {
  loginForm.classList.add('hidden');
  registrationForm.classList.remove('hidden');
  registraionButton.classList.remove('bg-black');
  registraionButton.classList.add('bg-white');
  loginButton.classList.remove('bg-white');
  loginButton.classList.add('bg-black');
  registerText.classList.remove('text-white');
  registerText.classList.add('text-black');
  loginText.classList.remove('text-black');
  loginText.classList.add('text-white');
  registerMessage.classList.remove('hidden');
  loginMessage.classList.add('hidden');
}

function toggleLogin() {
  loginForm.classList.remove('hidden');
  registrationForm.classList.add('hidden');
  loginButton.classList.remove('bg-black');
  loginButton.classList.add('bg-white');
  registraionButton.classList.remove('bg-white');
  registraionButton.classList.add('bg-black');
  registerText.classList.remove('text-black');
  registerText.classList.add('text-white');
  loginText.classList.remove('text-white');
  loginText.classList.add('text-black');
  registerMessage.classList.add('hidden');
  loginMessage.classList.remove('hidden');
}
